<template>
  <b-card-code title="بصمة المنتسبين" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- filter -->
        <b-form-group
          label="فلتر"
          label-cols-sm="1"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="title"
              type="search"
              placeholder="ابحث عن .."
            />
            <b-input-group-append>
              <b-button @click="resetSearch()">
                الغاء الفلتر
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-modal.model-a-w
              >
                <feather-icon icon="FilePlusIcon" class="mr-50" />
                <span class="align-middle">رفع بصمة المنتسبين</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                v-b-modal.modal-center
                
              >
                <feather-icon icon="FilePlusIcon" class="mr-50" />
                <span class="align-middle">حذف بيانات البصمة</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="printProgress()"
                >
                  <feather-icon icon="FilePlusIcon" class="mr-50" />
                  <span class="align-middle">طباعة البصمة</span>
                </b-button>
                <!-- <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="testWeekRefrence()"
                >test</b-button> -->
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <b-form-row style="margin-top: 10px">
        <b-col md="4">
          <b-form-group>
            <v-select
              v-model="search.department_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="departments"
              placeholder="اختر القسم"
            />
          </b-form-group>
        </b-col>
        <b-col md="3"
          >
            <b-form-group
            >
              <cleave
                id="date"
                v-model="search.fromdate"
                class="form-control"
                :raw="false"
                :options="date"
                placeholder="من YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>
          <b-col md="3"
          >
            <b-form-group
            >
              <cleave
                id="date"
                v-model="search.todate"
                class="form-control"
                :raw="false"
                :options="date"
                placeholder="الى YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
          <b-form-group>
            <v-select
              v-model="search.timeWork"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="search.timesWork"
              placeholder="اختر الدخول"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-card-body>
<div class="demo-vertical-spacing">
      <b-progress
      v-if="precentage !== 0"
        :value="precentage"
        max="100"
      />
      </div>
    <b-table
      ref="table"
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :items="items"
      :fields="fields"
    >
    <template #cell(icon)="icon">
     <div class="icon-wrapper">
          <feather-icon
            :icon="icon.item.icon"
            size="24"
          />
        </div>
    </template>
      <template #cell(action)="action">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-warning"
              v-b-modal.model-e-w
              @click="eRM(action.item)"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
            </b-button>
          </b-col>
        </b-dropdown>
      </template>
      <template #cell(presences)="res">
        <b-badge
          
          pill
          variant="primary"
        >
          <p v-if="res.item.presence_id==601" style="font-size: 30px; align-items: center;justify-content: center;">{{ Math.floor(res.item.daysDifference/7*res.item.days_of_week) }}</p>
          <p v-else style="font-size: 30px; align-items: center;justify-content: center;">{{ res.item.user_time_differences.length }}</p>
        </b-badge> 
      </template>
      <template #cell(wrong)="res">
        <b-badge
          
          pill
          variant="danger"
        >
        <p style="font-size: 30px; align-items: center;justify-content: center;">{{ reCOOP(res.item) }}</p>
        </b-badge> 
      </template>
      <template #cell(extra)="res">
        <b-badge
          
          pill
          variant="success"
        >
        <p style="font-size: 30px; align-items: center;justify-content: center;">{{ reET(res.item) }}</p>
        </b-badge> 
      </template>
      <template #cell(delay)="res">
        <b-badge
          
          pill
          variant="warning"
        >
        <p v-if="res.item.presence_id==601" style="font-size: 30px; align-items: center;justify-content: center;">6</p>
        <p v-else-if="res.item.presence_id==17" style="font-size: 30px; align-items: center;justify-content: center;">13</p>
        <p v-else style="font-size: 30px; align-items: center;justify-content: center;">{{ tde(res.item) }}</p>
        </b-badge> 
      </template>
      <template #cell(earlyleaving)="res">
        <b-badge
          
          pill
          variant="warning"
        >
        <p v-if="res.item.presence_id==601" style="font-size: 30px; align-items: center;justify-content: center;">6</p>
        <p v-else-if="res.item.presence_id==17" style="font-size: 30px; align-items: center;justify-content: center;">13</p>
        <p v-else style="font-size: 30px; align-items: center;justify-content: center;">{{ earlyleaving(res.item) }}</p>
        </b-badge> 
      </template>
      <template #cell(days)="res">
        <b-badge
          
          pill
          variant="success"
        >
        <p style="font-size: 30px; align-items: center;justify-content: center;">{{ Math.floor(res.item.daysDifference/7*res.item.days_of_week) }}</p>
        </b-badge> 
      </template>
      <template #cell(presence)="res">
        <b-badge
          
          pill
          variant="primary"
        >
        <p style="font-size: 30px; align-items: center;justify-content: center;">{{ res.item.presence_id }}</p>
        </b-badge> 
      </template>
      <template #cell(name)="res">
        <router-link :to="{ name: 'presence-check-list-show', params: { id: res.item.presence_id }}">
          {{res.item.name}}
        </router-link>
        
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          @change="check()"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
        @change="check()"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
    <b-modal
      id="model-e-w"
      ok-title="حفظ التعديلات"
      centered
      size="lg"
      title="تعديل الصلاحية"
      @show="checkModel"
      @ok="uM"
      @hidden="rEF"
    >
      <b-form novalidate class="needs-validation" style="margin-bottom:280px;">
        <b-form-row>
          <b-col md="12">
            <label for="input-valid1">العنوان:</label>
            <b-form-input
              id="input-valid1"
              v-model="editform.name"
              :state="editform.name.length > 0"
              placeholder="العنوان"
            />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">الصفحات:</label>
          <v-select
            v-model="editform.permissions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            :options="pages"
            label="اختر الصفحات"
          >
            <template #option="{ title, icon }">
              <feather-icon
                :icon="icon"
                size="16"
                class="align-middle mr-25"
              />
              <span> {{ title }}</span>
            </template>
          </v-select>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal
      id="model-a-w"
      ok-title="حفظ البيانات"
      centered
      size="lg"
      title="رفع اكسل البصمة"
      @show="checkModel"
      @ok="aNM"
      @hidden="rF"
    >
      <b-form novalidate class="needs-validation"  >
        <b-form-row>
          <b-col md="12">
          <label for="input-valid1">اختر الملف (اكسل حصرا):</label>
          <b-form-file
          placeholder="اختر الملف او اسقطها هنا..."
          drop-placeholder="اختر الملف او اسقطها هنا..."
            v-model="form.file"
             @change="selectFile"
          />
        </b-col>
        </b-form-row>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      title="حذف بيانات البصمة"
      @ok="dM"
      ok-title="خذف البيانات"
    >
      <b-card-text>
        هل تريد حذف بيانات البصمة ؟
      </b-card-text>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import store from "@/store";
import 'cleave.js/dist/addons/cleave-phone.us'
import Cleave from "vue-cleave-component";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import moment from 'moment';

import {
  BTable,
  BAvatar,
  BModal,
  BDropdown,BFormFile,
  BDropdownItem,
  BFormCheckboxGroup,
  BForm,
  BFormRow,
  BCol,
  VBModal,
  BBadge,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,BProgress,
  BButton,
  BCardBody,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { control } from "leaflet";

export default {
  components: {
    BCardCode,
    BTable,
    BForm,
    vSelect,BProgress,
    BAvatar,
    BBadge,BFormFile,
    BFormRow,
    BDropdown,
    BDropdownItem,Cleave,
    BFormGroup,
    togglePasswordVisibility,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormCheckboxGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BModal,
    VBModal,
    Ripple,
    BCol,
    BFormCheckbox,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,
  },
  data() {
    return {
      precentage:0,
      teacher_id: "",
      perPage: 3,
      pageOptions: [3, 5, 10, 100],
      totalRows: 1,
      currentPage: 0,
      role:"",
      title:"",
      filter: null,
      copres:0,
      filterOn: [],
      departments: [],
      date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
          },
      search:{
        department_id: '',
        todate: '',
        fromdate: '',
        timeWork:"09:15:00",
        timesWork : [{title:"08:15:00"},{title:"09:15:00"},{title:"10:30:00"}],
      },
      stages: [],
      fields: [
        {
          key: "name",
          label: "العنوان",
        },
        { key: "presence", label: "رقم البصمة" },
        { key: "days", label: "عدد الايام المطلوبة" },
        { key: "presences", label: "الحضور" },
        { key: "wrong", label: "بصمة وحدة" },
        { key: "extra", label: "وقت اضافي" },
        { key: "delay", label: "دخول متاخر" },
        { key: "earlyleaving", label: "خروج مبكر" },
      ],
      items: [],
      form: {
        file: "",
        permissions:[]
      },
      editform: {
        name: "",
        permissions:[],
        id: null,
      },
      selected: [],
      selected2: [],
      pages:[],
    };
  },
  watch: {
    role: function (val) {
      this.gS();
    },
    title: function (val) {
      this.gS();
    },
    "search.department_id": function (val) {
      if (val != null || val!="" ) {
        this.gS()
      }
    },
    "search.todate": function (val) {
      if (val != null || val!="" ) {
        this.gS()
      }
    },
    "search.timeWork": function (val) {
      if (val != null || val!="" ) {
        this.gS()
      }
    },
  },
  setup() {
      const printProgress = () => {
        window.print()
      }
  
      return {
        printProgress,
      }
    },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.ID(), this.gS(), this.gSM()
    this.calculateDaysWithoutFridays();
  },
  methods: {
    calculateDaysWithoutFridays() {
      let currentDate = moment("2024-01-28");
      const endDateObj = moment("2024-02-08");
      let numberOfDays = 0;

      while (currentDate.isSameOrBefore(endDateObj, 'day')) {
        if (currentDate.day() !== 5) {
          numberOfDays++;
        }
        currentDate.add(1, 'day');
      }
      this.numberOfDays = numberOfDays;
      console.log(this.numberOfDays)
    },
    async gSM() {
      await this.axios
        .get(`since/deparment?take=${this.perPage}&skip=${this.currentPage - 1
          }&select=true`)
        .then((res) => {
          this.departments = [];
          this.departments = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSM1() {
      this.stages = []
      this.editform.stage_id==""
      console.log(this.form.department_id)
      if(! Number.isInteger(this.editform.department_id)){
        this.editform.department_id = this.editform.department_id["id"]

      }
      await this.axios
        .get(`stage/clint/department/${this.editform.department_id}?select=true`)
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSM3() {
      this.stages = []
      this.form.stage_id==""
      if(! Number.isInteger(this.form.department_id)){
        this.form.department_id = this.form.department_id["id"]

      }
      await this.axios
        .get(`stage/clint/department/${this.form.department_id}?select=true`)
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSM2() {
      this.teachers = []
      this.editform.teacher_id==""
      await this.axios
        .get(`teachers-name`)
        .then((res) => {
          this.teachers = [];
          this.teachers = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    checkModel() {
      this.selected = [];
      if (this.editform.is_sustainabilty == 1) {
        this.selected.push("is_sustainabilty");
      }
      if (this.editform.is_energy == 1) {
        this.selected.push("is_energy");
      }
    },
    resetCheckboxes() {
      this.selected = [];
    },
    async uM() {
      await this.axios
        .post(`rules/${this.editform.id}`, this.editform,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
          }
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    selectFile(e) {
    this.form.file=""
    this.form.file = e.target.files[0]
  },
  async aNM() {
        let data = new FormData();
        data.append("file", this.form.file);
        await this.axios
          .post(`import/presence`, data,{
            onUploadProgress: progressEvent => {
              this.precentage =
                (progressEvent.loaded / progressEvent.total) * 100;
            }
          })
          .then((res) => {
            if (res.data.errors) {
              this.errorToast();
            } else {
              this.gS();
              this.makeToast(
                "success",
                "update Success",
                "Your model is added successfully"
              );
            }
            this.rF();
            this.precentage=0
          })
          .catch((e) => {
            if (e.message.includes("401")) {
              this.makeToast(
                "warning",
                "update failed",
                "you don't a permision to do this action ;)"
              );
            } else {
              this.errorToast();
            }
          });
        this.rF();
      },
    rF() {
      this.form.name = ""
      this.form.permissions = ""
    },
    rEF() {
      this.editform.name = "",
      this.editform.permissions = [],
      this.editform.id = ""
    },
    async dM() {
      await this.axios
        .delete(`presence/format`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gS();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    errorToast() {
      this.makeToast(
        "danger",
        "update failed",
        "some thing want wrong, i`m sorry :("
      );
    },
    eRM(item) {
      console.log(item)
      this.editform.name = item.name;
      this.editform.permissions = item.pages;
      this.editform.id = item.id;
    },
    dRM(item) {
      this.editform.name = item.name;
      this.editform.id = item.id;
    },
    check() {
      setTimeout(() => this.gS(), 1000);
    },
    ID() {
      this.teacher_id = localStorage.getItem("NisourTeacherId");
    },
    async gS() {
      await this.axios
        .get(`presence?take=${this.perPage}&skip=${this.currentPage - 1}&title=${this.title}&department_id=${this.search.department_id.id}&fromdate=${this.search.fromdate}&todate=${this.search.todate}
        `)
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
          this.search.fromdate = res.data.items[0].minimum;
          this.search.todate = res.data.items[0].maximum;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    resetSearch(){
      this.title = '',
      this.department = ''
      this.gS()
    },
   reCOOP(d){
    let totalCount = 0;
      for (const timeDifference of d.user_time_differences) {
        if (timeDifference.note === "Only one presence on this date."||timeDifference.note === "More than two presences on this date.") {
          totalCount++;
        }
    }
    return totalCount;
    },
   reET(d){
    let totalCount = 0;
      for (const timeDifference of d.user_time_differences) {
        if (timeDifference.note === "extra time") {
          totalCount++;
        }
    }
    return totalCount;
    },
    tde(d){
      let totalCount = 0;
       var t = this.search.timeWork.title;
      for (const timeDifference of d.user_time_differences) {
        if (timeDifference.note !== "Only one presence on this date."&&timeDifference.note !== "More than two presences on this date.") {
          var parts = timeDifference.time_differences[0].first_time.split(' ');
          var time = parts[1];
            if( time>t){
              totalCount++;
            }
        }
    }
    return totalCount;
    },
    earlyleaving(d){
      let totalCount = 0;
       var t = "15:50:00";
      for (const timeDifference of d.user_time_differences) {
        if (timeDifference.note !== "Only one presence on this date."&&timeDifference.note !== "More than two presences on this date.") {
          var parts = timeDifference.time_differences[0].second_time.split(' ');
          var time = parts[1];
            if( time<t){
              totalCount++;
            }
        }
    }
    return totalCount;
      },
      async testWeekRefrence() {
      try {
        await this.axios.post('weekRefrence/populate');
        
      } catch (error) {
        console.error('There was an error!', error);
      }
    }
  },
};
</script>
<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .prebutton{
    display: none !important;
  }
  .b-pagination{
    display: none !important;
  }
  .preper{
    display: none !important;
  }
  .demo-inline-spacing{
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }
  }
}
</style>